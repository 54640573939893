.outline {
  outline: 1px solid red;
}
.marnie-footer {
  align-items: center;
  background-color: #0f1b2a;
  bottom: 0;
  color: rgb(209, 213, 219);
  display: flex;
  font-size: 14px;
  gap: 10px;
  justify-content: space-between;
  left: 0;
  margin: 0;
  min-height: 40px;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  z-index: 2000;
}
@media only screen and (max-width: 493px) {
  .marnie-footer {
    padding: 10px 20px;
  }
}
@media only screen and (max-width: 688px) {
  .marnie-footer {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1182px) {
  .marnie-footer {
    padding: 2px 40px;
  }
}
.marnie-footer ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0;
  flex-wrap: wrap;
}

.marnie-footer ul li {
  border-right: 1px solid #7d8998;
  padding: 0 0.5em;
}

.marnie-footer ul li:last-child {
  border-right: none;
}

.marnie-footer ul a {
  cursor: pointer;
  color: rgb(209, 213, 219);
  text-decoration: underline;
}

.marnie-footer ul a:after {
  content: " ↗";
}

.marnie-footer .made-with-love {
  min-width: 164px;
  text-align: right;
}
@media only screen and (max-width: 688px) {
  .marnie-footer .made-with-love {
    text-align: left;
  }
}
.marnie-footer .made-with-love img {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  width: 34px;
  margin-top: 4px;
}
